// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/@prismicio/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktivitaeten-en-js": () => import("./../../../src/pages/aktivitaeten.en.js" /* webpackChunkName: "component---src-pages-aktivitaeten-en-js" */),
  "component---src-pages-aktivitaeten-js": () => import("./../../../src/pages/aktivitaeten.js" /* webpackChunkName: "component---src-pages-aktivitaeten-js" */),
  "component---src-pages-contactsuccess-en-js": () => import("./../../../src/pages/contactsuccess.en.js" /* webpackChunkName: "component---src-pages-contactsuccess-en-js" */),
  "component---src-pages-contactsuccess-js": () => import("./../../../src/pages/contactsuccess.js" /* webpackChunkName: "component---src-pages-contactsuccess-js" */),
  "component---src-pages-form-test-js": () => import("./../../../src/pages/formTest.js" /* webpackChunkName: "component---src-pages-form-test-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kinder-familien-programm-en-js": () => import("./../../../src/pages/kinder-familien-programm.en.js" /* webpackChunkName: "component---src-pages-kinder-familien-programm-en-js" */),
  "component---src-pages-kinder-familien-programm-js": () => import("./../../../src/pages/kinder-familien-programm.js" /* webpackChunkName: "component---src-pages-kinder-familien-programm-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-konzerte-en-js": () => import("./../../../src/pages/konzerte.en.js" /* webpackChunkName: "component---src-pages-konzerte-en-js" */),
  "component---src-pages-konzerte-js": () => import("./../../../src/pages/konzerte.js" /* webpackChunkName: "component---src-pages-konzerte-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-team-en-js": () => import("./../../../src/pages/team.en.js" /* webpackChunkName: "component---src-pages-team-en-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-uebersicht-en-js": () => import("./../../../src/pages/uebersicht.en.js" /* webpackChunkName: "component---src-pages-uebersicht-en-js" */),
  "component---src-pages-uebersicht-js": () => import("./../../../src/pages/uebersicht.js" /* webpackChunkName: "component---src-pages-uebersicht-js" */),
  "component---src-pages-zeremonien-en-js": () => import("./../../../src/pages/zeremonien.en.js" /* webpackChunkName: "component---src-pages-zeremonien-en-js" */),
  "component---src-pages-zeremonien-js": () => import("./../../../src/pages/zeremonien.js" /* webpackChunkName: "component---src-pages-zeremonien-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

