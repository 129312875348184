import React, { useState } from 'react';

export const myContext = React.createContext();

const Provider = props => {
    const [isEn, setLang] = useState(false);

    return (
        <myContext.Provider value={{
            isEn,
            changeLang: () => {

                setLang(!isEn)
            }
        }}>
            {props.children}
        </myContext.Provider>
    )
};

export default ({ element }) => (
    <Provider>
        {element}
    </Provider>
);