module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"pachamamafestival","defaultLang":"de-ch","langs":["en-us","de-ch"],"path":"/preview","previews":true,"shortenUrlLangs":true,"pages":[{"type":"Page","match":"/:lang?/:uid","path":"/page-preview","component":"/opt/build/repo/src/templates/page.js","langs":["en-us","de-ch"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pachamama Festival","short_name":"PM Fest","start_url":"/","background_color":"black","theme_color":"black","display":"minimal-ui","icon":"src/images/Logo_temoraer.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1841b88a898be832d70abbc6e5c755a0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
